import React from 'react';
import { I18nextProvider } from 'react-i18next';
import 'reset-css';
import { ImgProvider, RENDER_IMAGE, RENDER_IMAGE_WITH_PLACEHOLDER_INITIALLY } from '@wix/communities-image-lib';
import { initI18n, isExperimentEnabled } from '@wix/communities-blog-client-common';
import { EXPERIMENT_FIX_LCP_ON_MOBILE } from '@wix/communities-blog-experiments';

import { connect } from '../../../common/components/runtime-context';
import ResponsiveListener from '../../../common/components/responsive-listener/responsive-listener';
import PermissionsProvider from '../../../common/components/permissions-provider';
import ComponentsProvider from '../../../common/components/components-provider';
import Router from '../../../feed-page/containers/router';
import { getLanguage, isSeo } from '../../../common/store/basic-params/basic-params-selectors';
import { isWebpSupported } from '../../../common/services/is-webp-supported';
import { getTranslations } from '../../../common/store/translations/translations-selectors';
import { withReduxStore } from '../../../common/components/runtime-context/with-redux-store';
import BlogPushNotifications from '../../../common/components/blog-push-notifications';
import PostListMobile from '../../../feed-page/components/post-list/post-list-mobile';
import PostListItem from '../../../feed-page/components/post-list-item';
import SideBySideMobile from '../../../feed-page/components/post-list-item/mobile/side-by-side';
import TextOnImageMobile from '../../../feed-page/components/post-list-item/mobile/text-on-image';
import { buildPlaceholderSrc } from '../../../common/services/build-placeholder-src';
import './app-root.scss';

class AppRoot extends React.Component {
  getI18nConfig = (language, translations) => {
    if (this.lastLanguage !== language) {
      this.i18nConfig = initI18n(language, translations);
      this.lastLanguage = language;
    }
    return this.i18nConfig;
  };

  render() {
    const { language, translations, isSeo, isExperimentFixLcpOnMobileEnabled } = this.props;
    const i18n = this.getI18nConfig(language, translations);

    return (
      <ResponsiveListener>
        <PermissionsProvider>
          <ComponentsProvider
            PostListMobile={PostListMobile}
            PostListItem={PostListItem}
            SideBySideMobile={SideBySideMobile}
            TextOnImageMobile={TextOnImageMobile}
          >
            <I18nextProvider i18n={i18n}>
              <ImgProvider
                renderMode={isSeo ? RENDER_IMAGE : RENDER_IMAGE_WITH_PLACEHOLDER_INITIALLY}
                buildPlaceholderSrc={isExperimentFixLcpOnMobileEnabled ? buildPlaceholderSrc : undefined}
                {...(isWebpSupported() ? { format: 'webp' } : {})}
              >
                <>
                  <Router />
                  <BlogPushNotifications />
                </>
              </ImgProvider>
            </I18nextProvider>
          </ComponentsProvider>
        </PermissionsProvider>
      </ResponsiveListener>
    );
  }
}

const mapRuntimeToProps = (state) => ({
  language: getLanguage(state),
  translations: getTranslations(state),
  isSeo: isSeo(state),
  isExperimentFixLcpOnMobileEnabled: isExperimentEnabled(state, EXPERIMENT_FIX_LCP_ON_MOBILE),
});

export default withReduxStore(connect(mapRuntimeToProps)(AppRoot));
